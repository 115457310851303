import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

interface Props {
  images: JSX.Element[]
  fadeSpan: number
}

export const FadeShow = ({ images, fadeSpan }: Props) => {
  const [visible, setVisible] = useState(0)

  useEffect(() => {
    const timer = setInterval(() => {
      setVisible((p) => (p === 2 ? 0 : p + 1))
    }, fadeSpan)

    return () => {
      clearInterval(timer)
    }
  }, [fadeSpan])

  return (
    <Wrapper>
      {images.map((img, i) => (
        <ImageCard key={i} visibility={String(visible === i)}>
          {img}
        </ImageCard>
      ))}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  height: calc((100vw - max(40px, 100vw - 960px)) / 1.75);
  width: 100%;
  align-self: center;
  position: relative;

  > div {
    position: absolute;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    height: calc((100vw - max(40px, 100vw - 960px)) / 1.3);
  }
`

const ImageCard = styled.div<any>`
  opacity: ${(props) => (props.visibility === 'true' ? 1 : 0)};
  transition: opacity 1s;

  > div {
    width: calc(100vw - max(40px, 100vw - 960px));
    height: calc((100vw - max(40px, 100vw - 960px)) / 1.75);
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    > div {
      width: calc(100vw - max(40px, 100vw - 960px));
      height: calc((100vw - max(40px, 100vw - 960px)) / 1.3);
    }
  }
`
