import React from 'react'
import styled from 'styled-components'

import { Center as _Center } from '../layout'
import { FadeShow } from '../fadeShow'

interface Props {
  images: JSX.Element[]
  fadeSpan: number
}

export const TopPageTemplate = ({ images, fadeSpan }: Props) => {
  return (
    <Center>
      <FadeShow images={images} fadeSpan={fadeSpan} />
    </Center>
  )
}

const Center = styled((props) => <_Center {...props} />)`
  display: flex;
  height: inherit;
  padding-top: 30px;
  padding-bottom: 30px;
`
