import React, { useMemo } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image'

import { BasicTemplate } from '../components/templates/basicTemplate'
import { TopPageTemplate } from '../components/templates/topPageTemplate'

import { useTopPageSettings } from '../hooks/useTopPageSettings'
import { useBasicSettings } from '../hooks/useBasicSettings'
import { getImageFileName } from '../utils/getImageFileName'

const IndexPage = () => {
  const { basicSettings } = useBasicSettings()
  const { topPageSettings } = useTopPageSettings()

  const data = useStaticQuery(graphql`
    {
      allFile(filter: { extension: { regex: "/(jpg)|(png)/" } }) {
        nodes {
          relativePath
          childImageSharp {
            gatsbyImageData(
              formats: [AUTO, WEBP]
              placeholder: BLURRED
              quality: 95
              width: 960
              webpOptions: { quality: 95 }
            )
          }
        }
      }
    }
  `)

  const imagesOrder = useMemo(() => topPageSettings.fadeshow.map((v: { image: string }) => getImageFileName(v.image)), [
    topPageSettings,
  ])

  const imageObj = useMemo(() => {
    const obj: { [key: string]: IGatsbyImageData } = {}

    data.allFile.nodes.forEach((n: any) => {
      if (imagesOrder.includes(n.relativePath)) {
        const img = getImage(n)
        if (img) obj[n.relativePath] = img
      }
    })

    return obj
  }, [data])

  const images = useMemo(
    () => imagesOrder.map((name: string) => <GatsbyImage image={imageObj[name]} alt={'best 3'} />),
    [imagesOrder, imageObj]
  )

  return (
    <BasicTemplate basicSettings={basicSettings}>
      <TopPageTemplate images={images} fadeSpan={topPageSettings.fadeSpan} />
    </BasicTemplate>
  )
}

export default IndexPage
