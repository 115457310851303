import { useStaticQuery, graphql } from 'gatsby'
import { TopPageSettings } from '../types/topPageSettings'

export const useTopPageSettings = (): { topPageSettings: TopPageSettings } => {
  const data = useStaticQuery(
    graphql`
      {
        markdownRemark(fileAbsolutePath: { regex: "/topPage.md/" }) {
          frontmatter {
            fadeSpan
            fadeshow {
              image
            }
          }
        }
      }
    `
  )

  return { topPageSettings: data.markdownRemark.frontmatter }
}
